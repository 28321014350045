// Custom button variants


@mixin button-variant-custom($clr, $bg, $bd, $clr-hover, $bg-hover, $bd-hover) {
  &,
  &:active,
  &.active,
  &:active:focus,
  &.active:focus,
  &:focus:active,
  &:focus {
    color: $clr;
    background-color: $bg;
    border-color: $bd;
  }

  .open > &.dropdown-toggle,
  &:hover {
    color: $clr-hover;
    background-color: $bg-hover;
    border-color: $bd-hover;
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    opacity: .5;
    pointer-events: none;
  }

  .badge {
    color: $bg;
    background-color: $clr;
  }
}

// Reset Button
@mixin reset-button() {
  display: inline-block;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  outline-offset: 0;
  cursor: pointer;
  -webkit-appearance: none;

  &::-moz-focus-inner {
    padding: 0;
    border: none;
  }
}
