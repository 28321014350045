// RD Navbar Corporate Light
// --------------------------------------------------

$rd-search-results-width: 800px;

.rd-navbar-default {

  // RD Navbar Nav
  .rd-navbar-nav {
    > li > a {
      font: 400 16px $font-family-base;
      letter-spacing: .025em;
    }
  }

  // RD Navbar aside
  .rd-navbar-aside {
    width: 100%;
    @include font-size-default;
  }

  .btn {
    font-size: 16px;
  }

  // Mobile & tablet variant
  &.rd-navbar-fixed {
    // RD Navbar search
    .rd-navbar-search {
      padding: 0;

      .rd-search {
        position: fixed;
        width: 100%;
        padding: 8px 10px;
        background: $white;
        visibility: hidden;
        opacity: 0;
        @include transform(translateY(-80%));
        @extend %rd-navbar-transition;
        @include box-shadow($shadow-area-sm);
      }

      &.active {
        .rd-search {
          visibility: visible;
          opacity: 1;
          @include transform(translateY(0));
        }
      }

      .form-control {
        padding: 7px 46px 10px 22px;
      }

      .rd-navbar-search-toggle,
      .rd-search-submit {
        font-size: 20px;
        @include link($gray-base, $brand-primary);
      }

      .rd-navbar-search-toggle {
        position: fixed;
        top: 10px;
        right: 56px;
        z-index: 1000;
      }

      .rd-search-submit {
        right: 10px;
      }

      .rd-search-results-live {
        display: none;
      }
    }

    // RD Navbar Aside
    .rd-navbar-aside {
      &-content {
        background: $white;
        border: 1px solid $porcelain;
      }

      .list-units {
        > li + li {
          margin-top: 10px;
        }
      }

      * + .rd-navbar-aside-group {
        margin-top: 14px;
      }

      @media (min-width: $screen-xs-min) {
        &-content {
          width: auto;
        }
      }
    }

    // Btn wrap
    .rd-navbar-btn-wrap {
      padding: 16px 5px;
    }
  }

  // Desktop variant
  &.rd-navbar-static {
    .rd-navbar-group {
      padding: 33px 15px;
      @include display-flex;
      @include flex-direction(row);
      @include align-items(center);
      @include justify-content(space-between);
      @extend %rd-navbar-transition;
    }

    // RD Navbar Inner
    .rd-navbar-inner {
      padding: 0;
      font-size: 0;
      line-height: 0;
    }

    // RD Navbar Panel
    .rd-navbar-panel {
      min-width: 100px;
    }

    // RD Navbar aside
    .rd-navbar-aside {
      position: relative;
      z-index: 100;

      &-wrap,
      .rd-navbar-aside-content,
      .rd-navbar-aside-group {
        @include display-flex;
        @include flex-direction(row);
        @include align-items(center);
      }

      &-wrap {
        position: relative;
        z-index: 1001;
        padding: 6px 20px 6px 10px;
        @include flex-wrap(nowrap);

        &:after {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 50%;
          width: 101vw;
          border-bottom: 1px solid $porcelain;
          content: '';
          @include transform(translateX(-50%));
        }

        > * + * {
          margin-left: 6px;
        }
      }

      .rd-navbar-aside-content {
        margin-bottom: -5px;
        @include transform(translateY(-5px));
        @include justify-content(space-between);
      }

      .rd-navbar-aside-group {
        @include flex-shrink(0);

        &:first-child {
          margin-top: 7px;
          @include flex-grow(8);
          @include justify-content(flex-start);
          margin-right: 20px;
        }

        &:last-child {
          margin-top: 5px;
          @include justify-content(flex-end);
          @include flex-grow(1);
        }
      }

      .list-units {
        li {
          display: inline-block;
          margin-top: 0;

          &:not(:last-child) {
            margin-right: 30px;
          }
        }
      }
    }

    // RD Navbar Nav Inner
    .rd-navbar-nav-inner {
      @include display-flex;
      @include flex-direction(row-reverse);
      @include flex-wrap(nowrap);
      @include align-items(center);
      @include justify-content(flex-start);
    }

    // RD Navbar Nav
    .rd-navbar-nav {
      margin-right: 40px;

      > li {
        > a {
          padding: 7px 0;
          font-size: 15px;
        }

        &.rd-navbar-submenu {
          margin-right: -24px;
        }

        > .rd-navbar-submenu-toggle {
          position: relative;
          top: 2px;
          display: inline-block;
        }
      }

      > li + li {
        margin-left: 37px;
      }
    }

    @media (min-width: $screen-lg-min) {
      .rd-navbar-nav {
        > li > a {
          font-size: 16px;
        }

        > li + li {
          margin-left: 48px;
        }
      }
    }

    // RD Navbar Toggle
    .rd-navbar-toggle {
      .rd-navbar-nav {
        > li {
          .rd-navbar-toggle {
            display: none;
          }
        }
      }
    }

    // RD Navbar states
    &.rd-navbar--is-clone,
    &.rd-navbar--is-stuck {
      .rd-navbar-aside-wrap {
        position: absolute;
        top: -60px;
      }

      // RD Navbar Group
      .rd-navbar-group {
        padding-top: 14px;
        padding-bottom: 14px;
      }

      .rd-navbar-nav > li > .rd-navbar-dropdown {
        margin-top: 18px;
      }
    }
  }
}
