// Sections
// --------------------------------------------------

// Section Spacing
// -------------------------

$insets: (15px, 30px, 35px, 40px, 50px, 60px, 66px, 75px, 90px, 100px, 120px, 145px, 165px);

@include indent-responsive(section, '', (padding-top, padding-bottom), $medias, $insets);
@include indent-responsive(section, top, padding-top, $medias, $insets);
@include indent-responsive(section, bottom, padding-bottom, $medias, $insets);
