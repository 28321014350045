html {
  h1 {
    font-size: 30px;
    line-height: 1.18644;
    @media (min-width: $screen-sm-min) {
      font-size: 59px;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 0.88889;
  }

  h3 {
    font: 700 18px/28px "PT Serif", "Times New Roman", Times, serif;
  }

  h2, h3 {
    margin: 20px 0;
  }

  h4 {
    a {
      font-size: 80%;
    }
  }

  noscript {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
    width: 100%;
    height: 50px;
    color: #fff;
    line-height: 50px;
    text-align: center;
    background-color: #c00;
  }

  .panel-title {
    padding: 24px 55px 22px 32px;
  }

  .panel-light .panel-collapse {
    border-bottom: none;
  }

  .page p {
    a,
    a:active,
    a:focus {
      color: $brand-primary;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  form {
    .form-label {
      &.focus,
      &.not-empty {
        opacity: 0;
      }
    }

    input.form-control {
      border: 1px solid rgba(0, 0, 0, 0.1);
    }

    span.alert-danger {
      display: none;
      background-color: transparent;
    }

    .parsley-error {
      border: 1px solid $state-danger-border !important;
    }

    .parsley-error + span.alert-danger {
      display: block;
      color: $state-danger-text;
    }
  }

  #home {
    h1 {
      margin-bottom: 50px;
      font-size: 30px;
      text-align: center;
    }
  }

  #sitemap {
    ul.list-marked li:not(:last-child):after {
      content: '';
    }

    .list-marked li:last-child:after {
      content: '';
    }
  }

  #privacy {
    p {
      span {
        display: block;
      }
    }
  }

  .brand {
    top: 9px;

    span {
      padding-left: 20px;
      font-size: 15px;
    }

    img {
      width: 30px;
      @media (min-width: $screen-sm-min) {
        width: 35px;
      }
      @media (min-width: $screen-md-min) {
        width: 75px;
      }
    }
  }
}

#teaser {
  padding: 10px;
  color: $white;

  @media (min-width: $screen-sm-min) {
    padding: 7%;
    background: url("../images/familienrecht-fachanwalt.jpg.webp") no-repeat 0 0;
    background-size: cover;
  }

  .shell {
    text-align: right;
    @media (min-width: $screen-sm-min) {
      max-width: 400px;
      margin: 0 0 0 260px;
    }
    @media (min-width: $screen-md-min) {
      max-width: 500px;
      margin: 0 0 0 380px;
    }
    @media (min-width: $screen-lg-min) {
      max-width: 100%;
      margin: 0;
      text-align: center;
    }
  }

  h2 {
    color: $white;
  }
}

.thumbnail-profile {
  text-align: center;
  @media (min-width: $screen-sm-min) {
    text-align: left;
  }
}
