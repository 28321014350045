@use "sass:string";

@mixin bg-variant-custom($color, $fallback: null) {
  & {
    @if not (string.index($color, linear-gradient)) {
      background: $color;
      fill: $color;
    } @else {
      background: $fallback;
      background: -moz-#{$color};
      background: -webkit-#{$color};
      background: -o-#{$color};
      background: -ms-#{$color};
      background: #{$color};
      fill: $fallback;
    }
    @content;
  }
}
