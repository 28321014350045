// Element-groups

@mixin element-groups-custom($offset, $translate-offset) {
  margin-bottom: -$translate-offset;
  margin-left: -$translate-offset;
  -webkit-transform: translateY(-$translate-offset);
  transform: translateY(-$translate-offset);

  > *, > *:first-child {
    display: inline-block;
    margin-top: $offset;
    margin-left: $offset;
  }
}
