/*
* Navbar components
*/

// RD Navbar
.rd-navbar {
  display: none;
  background: $rd-navbar-background;
  box-shadow: $rd-navbar-shadow;
}

// RD Navbar Toggle
.rd-navbar-toggle {
  @include make-toggle(
                  $rd-navbar-toggle-preset,
                  $rd-navbar-min-line-height,
                  $rd-navbar-min-font-size,
                  $rd-navbar-fixed-toggle-color
  );
  display: none;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

// RD Navbar Brand
.rd-navbar-brand {
  transition: none !important;

  svg {
    fill: $gray-base;
  }
}

// RD Navbar Search
.rd-navbar-search {
  .rd-navbar-search-toggle,
  .rd-search-submit {
    @include reset-button;
  }

  .form-control {
    &::-ms-clear {
      display: none;
    }
  }
}

// RD Navbar Search Toggle
.rd-navbar-search-toggle {
  display: inline-block;
  width: 36px;
  height: 36px;
  font: 400 18px/36px $font-family-fontawesome;
  text-align: center;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    content: '\f002';
  }

  &:after {
    display: none;
  }

}

// RD Navbar aside
.rd-navbar-aside {
  pointer-events: none;

  > * {
    pointer-events: auto;
  }
}

.rd-navbar-aside-toggle {
  display: none;
  pointer-events: auto;
}

@mixin search-toggle-interactive($size, $before, $after) {
  &,
  &:before,
  &:after {
    width: 36px;
    height: 36px;
    text-align: center;
    @extend %rd-navbar-transition;
  }

  &:before,
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
  }

  &:before {
    content: $before;
    @include transform(scale(1) rotate(0deg));
  }

  &:after {
    opacity: 0;
    content: $after;
    @include transform(scale(0) rotate(-90deg));
  }

  &.active {
    &:before {
      opacity: 0;
      @include transform(scale(0) rotate(90deg));
    }

    &:after {
      opacity: 1;
      @include transform(scale(1) rotate(0deg));
    }
  }

}
