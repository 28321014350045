// Element groups
// --------------------------------------------------

html {
  .group {
    @include element-groups-custom(15px, 15px);
  }

  .group-xs {
    @include element-groups-custom(5px, 5px);
  }

  .group-sm {
    @include element-groups-custom(10px, 10px);
  }

  .group-md {
    @include element-groups-custom(15px, 15px);
  }

  .group-lg {
    @include element-groups-custom(20px, 20px);
  }

  .group-xl {
    @include element-groups-custom(30px, 30px);
  }

  .group-top {
    > *, > *:first-child {
      vertical-align: top;
    }
  }

  .group-middle {
    > *, > *:first-child {
      vertical-align: middle;
    }
  }

  .group-bottom {
    > *, > *:first-child {
      vertical-align: bottom;
    }
  }

  .group-inline {
    > * {
      display: inline;
      &:not(:last-child) {
        margin-right: .25em;
      }
    }
  }

  .group-xl-responsive {
    @include element-groups-custom(18px, 18px);

    @media (min-width: $screen-sm-min) {
      @include element-groups-custom(30px, 30px);
    }
  }
}

.group-flex-center {
  @include display-inline-flex;
  @include flex-direction(row);
  @include flex-wrap(wrap);
  @include align-items(center);
  @include justify-content(center);
}