// Dividers
// --------------------------------------------------
.divider {
  width: 49px;
  height: 2px;
  margin-right: auto;
  margin-left: auto;
}

.divider-fullwidth {
  width: 100%;
  height: 1px;
}

.divider-wide {
  width: 100%;
  height: 10px;
}

.divider-circle {
  position: relative;
  width: 100%;
  height: 10px;

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    background: $brand-primary;
    border-radius: 50px;
    content: '';
    @include transform(translate(-50%, -50%));
  }
}

// Sizing
.divider-md {
  width: 119px;
  height: 1px;
}

// Offsets
* + .divider-circle {
  margin-top: 35px;
}

@media (min-width: $screen-sm-min) {
  * + .divider-circle {
    margin-top: 50px;
  }
}
