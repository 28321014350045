@use "sass:math";
@use "sass:color";

/*
* Fixed Layout
*/
.rd-navbar-fixed {
  display: block;

  // RD Navbar Brand
  .rd-navbar-brand {
    position: fixed;
    top: 12px;
    left: 64px;
    z-index: 17;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;

    @include display-flex;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include align-items(center);
    @include justify-content(flex-start);

    .brand-slogan {
      display: none;
    }
  }

  // RD Navbar Panel
  .rd-navbar-panel {
    @include display-flex();
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 999;
    height: $rd-navbar-fixed-height;
    padding: math.div(($rd-navbar-fixed-height - $rd-navbar-fixed-line-height), 2);
    color: $rd-navbar-fixed-panel-color;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: $rd-navbar-fixed-panel-background;
      border-bottom: $rd-navbar-fixed-border;
      box-shadow: $rd-navbar-fixed-shadow;
      content: '';
    }
  }

  // RD Navbar Toggle
  .rd-navbar-toggle {
    display: inline-block;
  }

  .rd-navbar-nav-wrap {
    position: fixed;
    top: -56px;
    bottom: -56px;
    left: 0;
    z-index: 998;
    width: 270px;
    padding: 112px 0 56px;

    overflow-x: hidden;
    overflow-y: auto;
    color: $white;
    background: $rd-navbar-background;
    border-right: $rd-navbar-fixed-border;
    -webkit-overflow-scrolling: touch;
    @extend %rd-navbar-transition;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: color.adjust($rd-navbar-background, $lightness: 10%);
      border: none;
      border-radius: 0;
      opacity: .2;
    }

    &::-webkit-scrollbar-track {
      background: $rd-navbar-background;
      border: none;
      border-radius: 0;
    }

    @include transform(translateX(-105%));

    &.active {
      @include transform(translateX(0));
    }
  }

  .rd-navbar-nav-inner {
    position: relative;
    z-index: 100000;
    @include display-flex;
    @include flex-direction(column);
    height: auto;
    padding: 10px 0 20px;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    > * {
      @include flex-shrink(0);
    }
  }

  .rd-navbar-nav {
    display: block;
    font-size: 16px;
    line-height: 26px;
    text-align: left;

    li {
      .rd-navbar-dropdown,
      .rd-navbar-megamenu {
        height: 0;
        overflow: hidden;
        opacity: 0;
        transition: opacity 0.3s, height 0.4s ease;
      }

      &.opened {
        > .rd-navbar-dropdown,
        > .rd-navbar-megamenu {
          height: auto;
          padding: 3px 0;
          opacity: 1;
        }

        > .rd-navbar-submenu-toggle {
          color: $rd-navbar-fixed-submenu-toggle-focus-color;

          &::after {
            @include transform(rotate(180deg));
            margin-top: -24px;
          }
        }
      }

      > a {
        display: block;
        padding: 14px 56px 14px 16px;
        color: $rd-navbar-fixed-panel-item-color;
        font-size: 16px;
      }

      &:hover,
      &.focus,
      &.active,
      &.opened {
        > a, > a:hover {
          color: $rd-navbar-fixed-panel-item-hover-color;
          background: $rd-navbar-fixed-panel-item-hover-background;
        }

        > .rd-navbar-submenu-toggle {
          &::after {
            color: $rd-navbar-fixed-submenu-toggle-focus-color;
          }
        }
      }
    }

    > li + li {
      margin-top: 4px;
    }

    .label-custom {
      position: relative;
      top: -1px;
      display: inline-block;
      margin: 0 0 0 8px;
      padding: 6.45px .5em 5px;
      font-size: 60%;
      vertical-align: middle;
    }
  }

  .rd-navbar-dropdown,
  .rd-navbar-list {
    > li > a {
      font-size: 12px;
      font-family: $font-family-base;
      line-height: 1.2;
    }
  }

  .rd-navbar-megamenu {
    .rd-megamenu-header {
      padding: 0 15px;
    }

    > li {
      padding-top: 15px;
    }

    // Offsets
    * + .rd-megamenu-header {
      margin-top: 15px;
    }

    * + .rd-navbar-list {
      margin-top: 10px;
    }
  }

  // RD Navbar Nav Dropdown
  .rd-navbar-dropdown,
  .rd-navbar-megamenu {
    display: none;
  }

  .rd-navbar-submenu {
    position: relative;
    @extend %rd-navbar-transition;

    li > a {
      padding-left: 30px;
      font-size: 14px;
    }

    .rd-navbar-dropdown li li > a,
    .rd-navbar-megamenu ul li li > a {
      padding-left: 48px;
    }

    &.opened {
      > .rd-navbar-dropdown,
      > .rd-navbar-megamenu {
        display: block;
      }
    }
  }

  // RD Navbar Search
  .rd-navbar-search,
  .rd-navbar-btn-wrap {
    display: block;
    padding: 16px 5px;
  }

  .rd-navbar-btn-wrap {
    padding: 16px 10px;

    .btn {
      width: 100%;
    }
  }

  // RD Navbar Submenu Toggle
  .rd-navbar-submenu-toggle {
    &::after {
      position: absolute;
      top: 24px;
      right: 0;
      z-index: 2;
      width: 65px;
      height: 44px;
      margin-top: -22px;
      color: $rd-navbar-fixed-submenu-toggle-color;
      font: 400 15px $font-family-fontawesome;
      line-height: 42px;
      text-align: center;
      cursor: pointer;
      transition: 0.4s all ease;
      content: '\f107';
      will-change: transform;
    }
  }

  // Hybrid Styles
  .rd-navbar-collapse,
  .rd-navbar-search-toggle {
    position: fixed;
    top: math.div(($rd-navbar-fixed-height - $rd-navbar-fixed-line-height), 2);
    z-index: 1000;
    height: $rd-navbar-fixed-line-height;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }
  }

  // RD Navbar aside
  .rd-navbar-aside {
    &, .rd-navbar-aside-toggle {
      position: fixed;
      z-index: 1000;
      display: block;
      height: $rd-navbar-min-line-height;
    }

    top: 0;
    right: 0;
    width: 100%;

    &.active {
      .rd-navbar-aside-content {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .rd-navbar-aside-toggle {
    top: math.div(($rd-navbar-fixed-height - $rd-navbar-min-line-height), 2);
    right: math.div(($rd-navbar-fixed-height - $rd-navbar-min-line-height), 2);
    width: $rd-navbar-min-line-height;
    @include make-toggle(
                    'collapse-preset-1',
                    $rd-navbar-min-line-height,
                    $rd-navbar-min-font-size,
                    $rd-navbar-fixed-toggle-color
    );
  }

  .rd-navbar-aside-content {
    position: absolute;
    top: calc(100% + 7px);
    right: 0;
    width: calc(100% + 2px);
    margin: 0 -1px;
    padding: 20px 35px;
    visibility: hidden;
    opacity: 0;
    transition: .23s all ease-out;
    pointer-events: auto;

    @media (min-width: $screen-sm-min) {
      width: auto;
    }
  }

  // RD Navbar States
  &.rd-navbar--is-clone {
    display: none;
  }

  .rd-navbar-fixed--visible {
    display: block;
  }

  .rd-navbar-fixed--hidden {
    display: none;
  }
}

html.rd-navbar-fixed-linked {
  .page {
    padding-top: 56px;
  }
}
