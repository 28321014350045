// Offsets
// --------------------------------------------------


// Insets
// -------------------------

$insets: (0, 10px, 15px, 20px, 30px, 40px, 50px, 60px, 70px, 85px, 100px);

@include indent-responsive(inset, left, padding-left, $medias, $insets);
@include indent-responsive(inset, right, padding-right, $medias, $insets);

// Elements
// -------------------------


// Grid
// -------------------------


// Sections
// -------------------------


// Responsive
// -------------------------

$offsets: (0, 15px, 22px, 30px, 35px, 40px, 45px, 50px, 60px, 75px, 90px, 100px, 120px);

html .page {
  @include indent-responsive(offset, top, margin-top, $medias, $offsets);
}


.shift-md-top-1 {
  @media (min-width: $screen-md-min) {
    margin-top: -33px;
  }

  @media (min-width: $screen-lg-min) {
    margin-top: -43px;
  }
}
