@use "sass:color";

// Custom Thumbnails
// --------------------------------------------------

.thumbnail {
  position: relative;
  z-index: 1;
  width: 100%;
  max-height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  border: none;
  border-radius: 0;

  .caption {
    padding: 0;
  }
}

.img-thumbnail,
.thumbnail {
  box-shadow: none;
}

// Variant 1
.thumbnail-variant-1 {
  text-align: center;
  background-color: transparent;

  .thumbnail-image {
    position: relative;
    display: inline-block;
    overflow: hidden;
    pointer-events: none;

    &,
    > img {
      border-radius: 600px;
    }

    > img {
      width: auto;
      pointer-events: auto;
    }

    &-inner {
      position: absolute;
      top: 0;
      right: 1px;
      bottom: 0;
      left: 1px;
      z-index: 2;
      padding: 20px 5px 5px;
      background: rgba($gray-base, .4);
      border-radius: 600px;
      @include display-flex;
      @include flex-direction(row);
      @include flex-wrap(nowrap);
      @include align-items(center);
      @include justify-content(center);

      * {
        pointer-events: auto;
      }

      > * + * {
        margin-top: 0;
        margin-left: 20px;
      }
    }
  }

  .header {
    line-height: 1.2;
  }

  // Offsets
  * + p {
    margin-top: 0;
  }

  * + .thumbnail-caption {
    margin-top: 18px;
  }

  .desktop & {
    @media (min-width: $screen-md-min) {
      .thumbnail-image-inner {
        background: rgba($gray-base, .6);
        visibility: hidden;
        opacity: 0;
        transition: .55s all ease;
        @include transform(rotate3d(0, 1, 0, 60deg));
      }

      .thumbnail-image:hover {
        .thumbnail-image-inner {
          visibility: visible;
          opacity: 1;
          @include transform(rotate3d(0, 1, 0, 0deg));
        }
      }
    }
  }

  @media (min-width: $screen-lg-min) {
    * + .thumbnail-caption {
      margin-top: 30px;
    }
  }
}

// Variant 2
.thumbnail-variant-2 {
  min-height: 300px;
  padding: 30px 0 0;
  overflow: visible;
  text-align: center;
  @include display-flex;
  @include flex-direction(column);
  @include flex-wrap(nowrap);
  @include align-items(center);
  @include justify-content(flex-end);

  &-wrap {
    padding-bottom: 25px;
  }

  .thumbnail-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    > img {
      position: absolute;
      top: 20%;
      left: 50%;
      width: auto;
      min-width: 101%;
      max-width: none;
      height: auto;
      min-height: 100%;
      max-height: none;
      @include transform(translate(-50%, -20%));
    }
  }

  &:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: rgba($ebony-clay, .76);
    content: '';
  }

  .thumbnail-inner {
    position: relative;
    z-index: 2;
    padding: 30px 10px;

    a {
      font-weight: 700;
    }
  }

  .thumbnail-caption {
    position: relative;
    z-index: 3;
    width: calc(100% - 34px);
    margin: 31px 17px -25px 17px;
    padding: 17px 8px 25px;
    background: $brand-primary;

    * {
      color: $white;
    }

    a {
      @include link($white, rgba($white, .6));
    }
  }

  .text-header {
    font: 700 18px/24px $font-family-sec;
  }

  .text-caption {
    font-style: italic;
    line-height: 1.3;
    @media (min-width: $screen-sm-min) {
      font-size: 16px;
    }
  }

  // Offsets
  * + .divider {
    margin-top: 8px;
  }

  .divider + * {
    margin-top: 8px;
  }

  .desktop & {
    @media (min-width: $screen-md-min) {
      &:before {
        top: 40px;
      }

      .thumbnail-inner {
        > * {
          position: relative;
          @include transform(translateY(14px));
          transition: .4s all ease-in-out;
        }
      }

      &:before,
      .thumbnail-inner {
        visibility: hidden;
        opacity: 0;
        transition: .33s all ease-out;
      }

      &:hover {
        &:before {
          top: 0;
          right: 0;
          left: 0;
        }

        .thumbnail-inner {
          > * {
            @include transform(translateY(0));
          }
        }

        &:before,
        .thumbnail-inner {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  @media (min-width: $screen-md-min) {
    .thumbnail-caption {
      width: calc(100% - 16px);
      margin: 31px 8px -25px 8px;
    }
  }

  @media (min-width: $screen-lg-min) {
    width: calc(100% - 22px);
    margin: 0 11px 0;
    .thumbnail-caption {
      width: calc(100% - 34px);
      margin: 31px 17px -25px 17px;
    }
  }
}

// Variant 3
.thumbnail-variant-3 {
  width: 100.025%;
  text-align: center;
  $transition: .28s cubic-bezier(.79, .14, .15, .86);

  img {
    position: relative;
    left: 50%;
    width: auto;
    min-width: 100.5%;
    max-width: none;
    @include transform(translateX(-50%));
  }

  .link-external {
    position: absolute;
    top: -30px;
    right: -30px;
    z-index: 1;
    width: 200px;
    height: 110px;
    padding: 55px 15px 5px;
    line-height: 40px;
    text-align: center;
    vertical-align: bottom;
    background: color.adjust($white, $lightness: -2%);
    transform-origin: 74% 110%;
    /**
      @bugfix: color flickering in child objects on hover
      @affected: IE Edge
    */
    transition: top $transition, right $transition, opacity $transition, visibility $transition;
    will-change: transform;
    @include display-flex;
    @include align-items(center);
    @include justify-content(center);
    @include transform(rotate(45deg));

    .icon {
      color: $gray-base;
      vertical-align: bottom;
      transition: .22s;
      @include transform(rotate(-45deg));
    }

    &:hover {
      top: -12px;
      right: -12px;
    }
  }

  .desktop & {
    @media (min-width: $screen-md-min) {
      figure {
        img {
          transition: .4s ease-out;
          will-change: transform;
        }
      }
      .caption,
      .link-external {
        visibility: hidden;
        opacity: 0;
      }

      .link-external {
        top: -50px;
        right: -50px;
      }

      &:hover {
        .caption,
        .link-external {
          visibility: visible;
          opacity: 1;
        }

        figure {
          img {
            @include transform(translateX(-50%) scale(1.08));
          }
        }

        .link-external {
          top: -30px;
          right: -30px;

          &:hover {
            top: -20px;
            right: -20px;
          }
        }
      }
    }
  }

  > * + * {
    margin-top: 0;
  }
}

// Variant 4
.thumbnail-wrap {
  @media (min-width: $screen-sm-min) {
    padding: 0 5px;
  }

  @media (min-width: $screen-lg-min) {
    padding: 0 9px;
  }
}

// Thumbnail profile
.thumbnail-profile {
  .thumbnail-image {
    img {
      width: 100%;
    }
  }

  .thumbnail-caption {
    padding: 20px;
    background: $whisper;
  }

  .thumbnail-caption-inner {
    max-width: 100%;
    margin-bottom: -12px;
    @include align-items(flex-end);
    @include transform(translateY(-12px));
    text-align: center;

    > * {
      display: inline-block;
      margin-top: 12px;
      @include flex-shrink(0);
    }

    &, > ul {
      @include display-flex;
      @include align-items(center);
      @include justify-content(center);
      @include flex-direction(row);
      @include flex-wrap(wrap);
    }

    ul {
      position: relative;
      max-width: 100%;
      margin-bottom: -3px;
      @include transform(translateY(-3px));
      @include flex-grow(2);

      > li {
        display: inline-block;
        margin-top: 3px;
        padding: 0 7px;
      }
    }

    .btn-wrap {
      @include flex-grow(1);
    }

    @media (min-width: $screen-xs-min) {
      &, ul {
        @include justify-content(center);
      }
    }

    @media (min-width: $screen-md-min) {
      ul {
        @include justify-content(space-around);
      }
    }

    @media (min-width: $screen-lg-min) {
      text-align: left;
      @include justify-content(space-between);

      .btn-wrap {
        text-align: right;
      }
    }
  }
}

@media (max-width: $screen-xs-max) {
  .thumbnail-variant-2 {
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
  }

  .thumbnail-variant-3,
  .thumbnail-profile {
    max-width: 370px;
    margin-right: auto;
    margin-left: auto;
  }
}

// Make thumbnails to fit entire container width
.thumbnail-block {
  display: block;

  > img,
  a > img {
    width: 100%;
    height: auto;
  }
}
