// Responsive unit
// --------------------------------------------------

@include unit-responsive($medias, 20px, 30px);

// Unit Spacing


.unit-spacing-xs {
  @include unit-spacing($medias, 8px);
}

.unit-spacing-sm {
  @include unit-spacing($medias, 15px);
}

.unit-spacing-md {
  @include unit-spacing($medias, 22px);
}

.unit-spacing-lg {
  @include unit-spacing($medias, 30px);
}

.unit-middle {
  .unit-left {
    @include display-flex;
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(center);
  }
}
