// Page title
// --------------------------------------------------
$page-title-spacing: 1.2em;

.page-title {
  text-align: center;

  * {
    letter-spacing: 0;
  }

  .page-title-inner {
    position: relative;
    display: inline-block;
  }

  .page-title-left,
  .page-title-right {
    position: absolute;
    top: 50%;
    width: auto;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;

    * {
      display: inline;
      color: rgba($white, .1);
      white-space: nowrap;
    }
  }

  .page-title-left {
    left: 0;
    text-align: right;
    @include transform(translate(-100%, -50%));

    * {
      padding-right: .5em;
    }
  }

  .page-title-right {
    right: 0;
    text-align: left;
    @include transform(translate(100%, -50%));

    * {
      padding-left: .5em;
    }
  }
}

.page-title-wrap {
  background: $gray-base;
  background-position: center 80%;
  -webkit-background-size: cover;
  background-size: cover;
  background-attachment: fixed;
}

@media (min-width: $screen-sm-min) {
  .page-title {
    text-align: left;

    .page-title-left {
      * {
        padding-right: $page-title-spacing;
      }
    }

    .page-title-right {
      * {
        padding-left: $page-title-spacing;
      }
    }
  }
}
