// Custom Buttons Styles
// --------------------------------------------------

// Base styles
// --------------------------------------------------

.btn {
  max-width: 100%;
  font-weight: $btn-font-weight;
  font-size: $btn-font-size * 1px;
  font-family: $font-family-base;
  border: 2px solid;
  border-radius: $btn-border-radius;
  transition: .3s ease-out;

  &:focus,
  &:active,
  &:active:focus {
    outline: none;
  }

  &:active,
  &.active {
    @include box-shadow(none);
  }

  &-smaller {
    padding: 6px 32px;
  }

  &-small {
    padding-right: 20px;
    padding-left: 20px;
  }

  &-medium {
    padding-right: 40px;
    padding-left: 40px;
  }

  &.btn-icon {
    @include display-inline-flex;
    @include flex-direction(row);
    @include flex-wrap(nowrap);
    @include align-items(center);
    @include justify-content(center);
    vertical-align: middle;

    .icon {
      position: relative;
      top: 1px;
      display: inline-block;
      width: auto;
      height: auto;
      line-height: 0;
      vertical-align: middle;
      transition: 0s;
    }

    &-left {
      .icon {
        margin-right: 15px;
      }
    }

    &-right {
      @include flex-direction(row-reverse);

      .icon {
        margin-left: 15px;
      }
    }
  }
}


button.btn {
  @media (max-width: $screen-xs-max) {
    padding-right: 15px;
    padding-left: 15px;
  }
}

// Alternate buttons
// --------------------------------------------------
html {
  .btn-default {
    @include button-variant-custom($white, $abbey, $abbey, $white, $brand-primary, $brand-primary);
  }

  .btn-primary {
    @include button-variant-custom($btn-primary-color, $btn-primary-bg, $btn-primary-border, $white, $teak, $teak);
  }

  .btn-red-orange {
    @include button-variant-custom($white, $red-orange, $red-orange, $white, $gray-dark, $gray-dark);
  }

  .btn-oslo-gray {
    @include button-variant-custom($white, $oslo-gray, $oslo-gray, $white, $brand-primary, $brand-primary);
  }

  .btn-primary-contrast {
    @include button-variant-custom($white, $brand-primary, $brand-primary, $white, $teak, $teak);
  }

  .btn-primary-outline {
    @include button-variant-custom($brand-primary, transparent, $brand-primary, $white, $brand-primary, $brand-primary);
  }

  .btn-primary-outline-v2 {
    @include button-variant-custom($white, transparent, $brand-primary, $white, $brand-primary, $brand-primary);
  }

  .btn-white-outline {
    @include button-variant-custom($white, transparent, $white, $tundora, $white, $white);
  }

  .btn-white-outline-variant-1 {
    @include button-variant-custom($white, transparent, $white, $white, $brand-primary, $brand-primary);
  }

  .btn-silver-outline {
    @include button-variant-custom($gray-base, transparent, $silver, $white, $silver, $silver);
  }

  .btn-gray-base-outline {
    @include button-variant-custom($gray-base, transparent, $gray-base, $white, $gray-base, $gray-base);
  }
}

@media (max-width: $screen-xs-max) {
  .btn-responsive {
    min-width: 120px;
    padding-right: 25px;
    padding-left: 25px;
    font-size: 13px;
  }
}

// Button Sizes
// --------------------------------------------------

.btn-xs {
  min-width: 100px;
  @include button-size($padding-xs-vertical, $padding-xs-horizontal, 14px, $btn-border-radius);
}

.btn-sm {
  min-width: 120px;
  @include button-size($padding-small-vertical, $padding-small-horizontal, 14px, $btn-border-radius);
}

.btn-lg {
  min-width: 270px;
  @include button-size($padding-large-vertical, $padding-large-horizontal, 18px, $btn-border-radius);
}

.btn-xl {
  @include button-size(19px, 40px, 18px, $btn-border-radius);

  @media (min-width: $screen-sm-min) {
    min-width: 270px;
    padding: $padding-extra-large-vertical $padding-extra-large-horizontal;
  }

  // Variats
  &-bigger {
    min-width: 100px;
    @media (min-width: $screen-md-min) {
      min-width: 270px;
      padding-top: 24px;
      padding-bottom: 24px;
    }
  }
}

// Button Shapes
// --------------------------------------------------

.btn-block {
  min-width: 30px;
  max-width: 100%;
}

.btn-rect {
  border-radius: 0;
}

.btn-round {
  border-radius: 12px;
}

.btn-round-2 {
  border-radius: 6px;
}

.btn-circle {
  border-radius: 35px;
}

.btn-round-bottom {
  border-radius: 0 0 5px 5px;
}

// Button Modifiers
// --------------------------------------------------
.btn-shadow {
  @include box-shadow(-3px 3px 3px 0 rgba($gray-base, .14));
}


.btn-icon-only {
  @include reset-button;
  font-size: 0;
  line-height: 0;
  transition: .33s all ease;

  &.btn-icon-only-primary {
    @include link($brand-primary, $gray-base);
  }
}
