@use "sass:color";

// Text Utilities

@mixin make-text-alignment($resolution: null) {
  @if not ($resolution) {
    $resolution: ''
  } @else {
    $resolution: str_insert($resolution, '-', str_length($resolution) + 1);
  }
  .text-#{$resolution}left {
    text-align: left;
  }

  .text-#{$resolution}center {
    text-align: center;
  }

  .text-#{$resolution}right {
    text-align: right;
  }

  .text-#{$resolution}justify {
    text-align: justify;
  }

  .text-#{$resolution}nowrap {
    white-space: nowrap;
  }

  .text-#{$resolution}normal {
    white-space: normal;
  }
}

// Typography

// [converter] $parent hack
@mixin text-emphasis-variant($parent, $color) {
  #{$parent} {
    color: $color;
  }
  a#{$parent}:hover,
  a#{$parent}:focus {
    color: color.adjust($color, $lightness: -10%);
  }
}
