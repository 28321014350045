@use "sass:math";
@use "sass:list";
@use "sass:color";


// Gray and brand colors for use across Bootstrap.

$gray-base: #000;
$gray-darker: #00030a;
$gray-dark: #2a2b2b;
$gray: #9f9f9f;
$gray-light: #d9d9d9;
$gray-lighter: #f9f9f9;

$brand-primary: #cca876;
$brand-success: #58c476;
$brand-info: #3e9cf6;
$brand-warning: #c49558;
$brand-danger: #fe4a21;

$white: #fff;

$teak: #b49465;
$red-orange: #ff4b22;

$sisal: #d4cabb;
$laser: #cca876;
$fuscous-gray: #4c4943;
$leather: #997e58;

$gold: #ffd400;
$old-gold: #cfaa45;

$cello: #1e3953;

$mako: #444953;
$ebony-clay: #2b2f40;
$oxford-blue: #3d445b;
$bright-gray: #3d414e;
$storm-gray: #666c84;
$nobel: #b4b4b4;
$oslo-gray: #85868a;

$abbey: #535457;
$dusty-gray: #9b9b9b;
$gallery: #ededed;
$porcelain: #e5e7e9;
$whisper: #f2f3f8;

$cod-gray: #111;
$mine-shaft: #333;
$tundora: #414141;
$gunsmoke: #767877;
$silver-chalice: #ababab;
$silver: #cdcdcd;
$gallery: #eee;
$athens-gray: #e5e8ef;
$iron: #dcdde0;
$alto: #dedede;

$secondary: $gray-base;

//== Scaffolding

//## Settings for some of the most global styles.

$content-bg: $white;
$header-bg: $white;
$footer-bg: $gray-base;

//** Background color for `<body>`.
$body-bg: $white;
//** Global text color on `<body>`.
$text-color: $gray;

//** Global textual link color.
$link-color: $text-color;
//** Link hover color set via `darken()` function.
$link-hover-color: $brand-primary;
//** Link hover decoration.
$link-hover-decoration: none;


//== Typography

//## Font, line-height, and color for body text, headings, and more.

// Import fonts


$font-family-sans-serif: "Lato", Helvetica, Arial, sans-serif;
$font-family-serif: 'PT Serif', "Times New Roman", Times, serif;
$font-family-serif-1: 'Merriweather', "Times New Roman", Times, serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-family-mercury: 'Mercury';
$font-family-fontawesome: 'FontAwesome';
$font-family-flaticonset2: 'fl-flat-icons-set-2';


$font-family-base: $font-family-sans-serif;
$font-family-sec: $font-family-serif;


// Template width (w/o units)
$template-width: 1920;

// Template width var for resizing
$template-width-min: ($template-width + 1) * 1px;
$layout-boxed-width: 1920px;


// Base FZ & LH (w/o units)
$base-text-size: 14;
$base-lh-size: 24;

$base-vm-size: math.div(($base-text-size * 100),$template-width) * 1vw;


$font-size-base: $base-text-size * 1px;
$font-size-large: math.ceil(($font-size-base * 1.25)); // ~18px
$font-size-small: 12px;

$font-weight-base: 400;

//PSD FZ headers /FOR EDIT (w/o units)
$Size-h1: 104;
$Size-h2: 59;
$Size-h3: 45;
$Size-h4: 25;
$Size-h5: 19;
$Size-h6: 14;

//PSD LH headers /FOR EDIT (w/o units)
$Lh-h1: 112;
$Lh-h2: 70;
$Lh-h3: 40;
$Lh-h4: 38;
$Lh-h5: 28;
$Lh-h6: 24;


//Calculating FZ headers
$font-size-h1: $Size-h1 * 1px;
$font-size-h2: $Size-h2 * 1px;
$font-size-h3: $Size-h3 * 1px;
$font-size-h4: $Size-h4 * 1px;
$font-size-h5: $Size-h5 * 1px;
$font-size-h6: $Size-h6 * 1px;


//** line-height for headers /FOR EDIT
// $font-lh-h_: floor((PSD l-h / $font-size-h1));

$font-lh-h1: math.div($Lh-h1, $Size-h1);
$font-lh-h2: math.div($Lh-h2, $Size-h2);
$font-lh-h3: math.div($Lh-h3, $Size-h3);
$font-lh-h4: math.div($Lh-h4, $Size-h4);
$font-lh-h5: math.div($Lh-h5, $Size-h5);
$font-lh-h6: math.div($Lh-h6, $Size-h6);

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: math.div($base-lh-size, $base-text-size); // l-h/fz-base
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: math.floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: $font-family-sec;
$headings-font-weight: 700;
$headings-line-height: 1.1;
$headings-color: $gray-base;


//== Iconography

//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.

//** Load fonts from this directory.
$icon-font-path: "../fonts/";

//** File name for all font files.
$icon-font-name: "glyphicons-halflings-regular";
$icon-font-awesome: "fontawesome-webfont";
$icon-material-design: "materialdesignicons-webfont";

//** Element ID within SVG icon file.
$icon-font-svg-id: "glyphicons_halflingsregular";
$icon-font-awesome-id: "fontawesomeregular";
$icon-material-design-id: "materialdesigniconsregular";


//== Components

//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).

$padding-base-vertical: 9px;
$padding-base-horizontal: 55px;

$padding-large-vertical: 14px;
$padding-large-horizontal: 30px;

$padding-extra-large-vertical: 19px;
$padding-extra-large-horizontal: 45px;

$padding-small-vertical: 12px;
$padding-small-horizontal: 25px;

$padding-xs-vertical: 8px;
$padding-xs-horizontal: 40px;

$line-height-large: 1.4444; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5;

$border-radius-base: 0;
$border-radius-large: 6px;
$border-radius-small: 3px;

//** Global color for active items (e.g., navs or dropdowns).
$component-active-color: $gray-dark;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary;

//** Width of the `border` for generating carets that indicator dropdowns.
$caret-width-base: 4px;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px;


//== Tables

//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 17px 25px 18px;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px;

//** Default background color used for all tables.
$table-bg: transparent;
$table-header-bg: $whisper;
//** Background color used for `.table-striped`.
$table-bg-accent: $whisper;
//** Background color used for `.table-hover`.
$table-bg-hover: $whisper;
$table-bg-active: $table-bg-hover;

//** Border color for table and cell borders.
$table-border-color: #d9d9d9;


//== Buttons

//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-size: 17;
$btn-lh-size: 24;
$btn-border-radius: 0;

$btn-font-weight: 700;

$btn-default-color: $white;
$btn-default-bg: $gray-base;
$btn-default-border: $gray-base;

$btn-primary-color: $white;
$btn-primary-bg: $brand-primary;
$btn-primary-border: $brand-primary;

$btn-success-color: $white;
$btn-success-bg: $brand-success;
$btn-success-border: $brand-success;

$btn-info-color: $white;
$btn-info-bg: $brand-info;
$btn-info-border: $brand-info;

$btn-warning-color: $white;
$btn-warning-bg: $brand-warning;
$btn-warning-border: $brand-warning;

$btn-danger-color: $white;
$btn-danger-bg: $brand-danger;
$btn-danger-border: $brand-danger;

$btn-link-disabled-color: $gray-light;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: $border-radius-base;
$btn-border-radius-large: $border-radius-large;
$btn-border-radius-small: $border-radius-small;


//== Forms

//##

//** `<input>` background color
$input-bg: #fff;
//** `<input disabled>` background color
$input-bg-disabled: $gray-lighter;

//** Text color for `<input>`s
$input-color: $text-color;
//** `<input>` border color
$input-border: $gray-light;

//** Font size for input
$input-font-size: 14px;


//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $border-radius-base;
//** Large `.form-control` border radius
$input-border-radius-large: $border-radius-large;
//** Small `.form-control` border radius
$input-border-radius-small: $border-radius-small;

//** Border color for inputs on focus
$input-border-focus: $brand-primary;

//** Placeholder text color
$input-color-placeholder: $text-color;
$input-color-placeholder-focus: $brand-primary;

//** Default `.form-control` height
$input-height-base: 52px;
//** Large `.form-control` height
$input-height-large: (math.ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2);
//** Small `.form-control` height
$input-height-small: (math.floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2);

//** `.form-group` margin
$form-group-margin-bottom: 15px;

$legend-color: $gray-dark;
$legend-border-color: #e5e5e5;

//** Background color for textual input addons
$input-group-addon-bg: $gray-lighter;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border;

//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed;

//** Textarea default
$textarea-default-height: 166px;
$textarea-default-min-height: $input-height-base;
$textarea-default-max-height: $textarea-default-height * 1.5;

// Main input properties
$input-border-obj: 0px solid;
$input-padding-horizontal: 19px;
$input-line-height: $input-font-size * 1.7;

$input-padding-vertical: math.div(($input-height-base - $input-line-height), 2);
$input-padding: $input-padding-vertical $input-padding-horizontal;

@if ($input-border-obj != none) {
  $input-padding-vertical: math.div(($input-height-base - $input-line-height - (list.nth($input-border-obj, 1) * 2)), 2);
  $input-padding: $input-padding-vertical - 2px $input-padding-horizontal $input-padding-vertical + 2px;
}

//== Dropdowns


// $dropdown-font-size: 14;
// $dropdown-lh-size: 36;

//## Dropdown menu container and contents.

//** Background for the dropdown menu.
$dropdown-bg: #fff;
//** Dropdown menu `border-color`.
$dropdown-border: rgba(0, 0, 0, .15);
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: #ccc;
//** Divider color for between dropdown items.
$dropdown-divider-bg: #e5e5e5;

//** Dropdown link text color.
$dropdown-link-color: $gray-dark;
//** Hover color for dropdown links.
$dropdown-link-hover-color: color.adjust($gray-dark, $lightness: -5%);
//** Hover background for dropdown links.
$dropdown-link-hover-bg: #f5f5f5;

//** Active dropdown menu item text color.
$dropdown-link-active-color: $white;
//** Active dropdown menu item background color.
$dropdown-link-active-bg: $component-active-bg;

//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light;

//** Text color for headers within dropdown menus.
$dropdown-header-color: $gray-light;

//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: #000;


//-- Z-index master list

// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// Note: These variables are not generated into the Customizer.

$zindex-navbar: 1000;
$zindex-dropdown: 1000;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
$zindex-navbar-fixed: 1030;
$zindex-modal-background: 1040;
$zindex-modal: 1050;


//== Media queries breakpoints

//## Define the breakpoints at which your layout will change, adapting to different screen sizes.

// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;

// Small screen / tablet
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
$screen-md: 992px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;

// Extra Large screen / 4k desktop
$screen-xl: 1800px;
$screen-xl-min: $screen-xl;
$screen-xl-desktop: $screen-xl-min;

// So media queries don't overlap when required, provide a maximum
$screen-xxs-max: ($screen-xs-min - 1);
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
$screen-lg-max: ($screen-xl-min - 1);

// Define media prefix
$medias: (0: null, $screen-xs-min : xs, $screen-sm-min : sm, $screen-md-min : md, $screen-lg-min : lg, $screen-xl-min : xl);


//== Grid system

//## Define your custom responsive grid.

//** Number of columns in the grid.
$grid-columns: 12;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);


//== Container sizes

//## Define the maximum width of `.container` for different screen sizes.

// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width);
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet;

// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width);
//** For `$screen-md-min` and up.
$container-md: $container-desktop;

// Large screen / wide desktop
$container-large-desktop: (1170px + $grid-gutter-width);
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop;


//== Navbar

//##

// $navbar-font-size: 16;
// $navbar-lh-size: 18;

// Basics of a navbar
$navbar-height: 50px;
$navbar-margin-bottom: $line-height-computed;
$navbar-border-radius: $border-radius-base;
$navbar-padding-horizontal: math.floor(math.div($grid-gutter-width, 2));
$navbar-padding-vertical: math.div(($navbar-height - $line-height-computed), 2);
$navbar-collapse-max-height: 340px;

$navbar-default-color: #777;
$navbar-default-bg: #f8f8f8;
$navbar-default-border: color.adjust($navbar-default-bg, $lightness: -6.5%);

// Navbar links
$navbar-default-link-color: #777;
$navbar-default-link-hover-color: #333;
$navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: #555;
$navbar-default-link-active-bg: color.adjust($navbar-default-bg, $lightness: -6.5%);
$navbar-default-link-disabled-color: #ccc;
$navbar-default-link-disabled-bg: transparent;

// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color;
$navbar-default-brand-hover-color: color.adjust($navbar-default-brand-color, $lightness: -10%);
$navbar-default-brand-hover-bg: transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg: #ddd;
$navbar-default-toggle-icon-bar-bg: #888;
$navbar-default-toggle-border-color: #ddd;


//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: color.adjust($gray-light, $lightness: 15%);
$navbar-inverse-bg: #222;
$navbar-inverse-border: color.adjust($navbar-inverse-bg, $lightness: -10%);

// Inverted navbar links
$navbar-inverse-link-color: color.adjust($gray-light, $lightness: 15%);
$navbar-inverse-link-hover-color: #fff;
$navbar-inverse-link-hover-bg: transparent;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
$navbar-inverse-link-active-bg: color.adjust($navbar-inverse-bg, $lightness: -10%);
$navbar-inverse-link-disabled-color: #444;
$navbar-inverse-link-disabled-bg: transparent;

// Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color;
$navbar-inverse-brand-hover-color: #fff;
$navbar-inverse-brand-hover-bg: transparent;

// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333;
$navbar-inverse-toggle-icon-bar-bg: #fff;
$navbar-inverse-toggle-border-color: #333;


//== Navs

//##

//=== Shared nav styles
$nav-link-padding: 10px 15px;
$nav-link-hover-bg: $gray-lighter;

$nav-disabled-link-color: $gray-light;
$nav-disabled-link-hover-color: $gray-light;

//== Tabs
$nav-tabs-border-color: #ddd;

$nav-tabs-link-hover-border-color: $gray-lighter;

$nav-tabs-active-link-hover-bg: $body-bg;
$nav-tabs-active-link-hover-color: $gray;
$nav-tabs-active-link-hover-border-color: #ddd;

$nav-tabs-justified-link-border-color: #ddd;
$nav-tabs-justified-active-link-border-color: $body-bg;

//== Pills
$nav-pills-border-radius: $border-radius-base;
$nav-pills-active-link-hover-bg: $component-active-bg;
$nav-pills-active-link-hover-color: $component-active-color;


//== Pagination

//##

$pagination-color: $brand-primary;
$pagination-bg: transparent;
$pagination-border: $brand-primary;

$pagination-hover-color: $white;
$pagination-hover-bg: $brand-primary;
$pagination-hover-border: $pagination-hover-bg;

$pagination-active-color: $pagination-hover-color;
$pagination-active-bg: $pagination-hover-bg;
$pagination-active-border: $pagination-hover-border;

$pagination-disabled-color: $gray-lighter;
$pagination-disabled-bg: $silver;
$pagination-disabled-border: $silver;


//== Pager

//##

$pager-bg: $pagination-bg;
$pager-border: $pagination-border;
$pager-border-radius: 15px;

$pager-hover-bg: $pagination-hover-bg;

$pager-active-bg: $pagination-active-bg;
$pager-active-color: $pagination-active-color;

$pager-disabled-color: $pagination-disabled-color;


//== Jumbotron

//##

$jumbotron-padding: 30px;
$jumbotron-color: inherit;
$jumbotron-bg: $gray-darker;
$jumbotron-heading-color: inherit;
$jumbotron-font-size: math.ceil(($font-size-base * 1.5));
$jumbotron-heading-font-size: math.ceil(($font-size-base * 4.5));


//== Form states and alerts

//## Define colors for form feedback states and, by default, alerts.

$state-success-text: $brand-success;
$state-success-bg: #dff0d8;
$state-success-border: color.adjust(color.adjust($state-success-bg, $hue: -10), $lightness: -5%);

$state-info-text: $brand-info;
$state-info-bg: #d9edf7;
$state-info-border: color.adjust(color.adjust($state-info-bg, $hue: -10), $lightness: -7%);

$state-warning-text: $brand-warning;
$state-warning-bg: #fcf8e3;
$state-warning-border: color.adjust(color.adjust($state-warning-bg, $hue: -10), $lightness: -5%);

$state-danger-text: $brand-danger;
$state-danger-bg: $brand-danger;
$state-danger-border: $brand-danger;


//== Tooltips

//##

//** Tooltip max width
$tooltip-max-width: 200px;
//** Tooltip text color
$tooltip-color: #fff;
//** Tooltip background color
$tooltip-bg: #000;
$tooltip-opacity: .9;

//** Tooltip arrow width
$tooltip-arrow-width: 5px;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg;


//== Popovers

//##

//** Popover body background color
$popover-bg: #fff;
//** Popover maximum width
$popover-max-width: 276px;
//** Popover border color
$popover-border-color: rgba(0, 0, 0, .2);
//** Popover fallback border color
$popover-fallback-border-color: #ccc;

//** Popover title background color
$popover-title-bg: color.adjust($popover-bg, $lightness: -3%);

//** Popover arrow width
$popover-arrow-width: 10px;
//** Popover arrow color
$popover-arrow-color: $popover-bg;

//** Popover outer arrow width
$popover-arrow-outer-width: ($popover-arrow-width + 1);
//** Popover outer arrow color
$popover-arrow-outer-color: fade_in($popover-border-color, 0.05);
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color: color.adjust($popover-fallback-border-color, $lightness: -20%);


//== Labels

//##

//** Default label background color
$label-default-bg: $gray-light;
//** Primary label background color
$label-primary-bg: $brand-primary;
//** Success label background color
$label-success-bg: $brand-success;
//** Info label background color
$label-info-bg: $brand-info;
//** Warning label background color
$label-warning-bg: $brand-warning;
//** Danger label background color
$label-danger-bg: $brand-danger;

//** Default label text color
$label-color: #fff;
//** Default text color of a linked label
$label-link-hover-color: #fff;


//== Modals

//##

//** Padding applied to the modal body
$modal-inner-padding: 15px;

//** Padding applied to the modal title
$modal-title-padding: 15px;
//** Modal title line-height
$modal-title-line-height: $line-height-base;

//** Background color of modal content area
$modal-content-bg: #fff;
//** Modal content border color
$modal-content-border-color: rgba(0, 0, 0, .2);
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: #999;

//** Modal backdrop background color
$modal-backdrop-bg: #000;
//** Modal backdrop opacity
$modal-backdrop-opacity: .5;
//** Modal header border color
$modal-header-border-color: #e5e5e5;
//** Modal footer border color
$modal-footer-border-color: $modal-header-border-color;

$modal-lg: 900px;
$modal-md: 600px;
$modal-sm: 300px;


//== Alerts

//## Define alert colors, border radius, and padding.

$alert-padding: 15px;
$alert-border-radius: $border-radius-base;
$alert-link-font-weight: bold;

//$alert-success-bg:            $state-success-bg;
//$alert-success-text:          $state-success-text;
//$alert-success-border:        $state-success-border;

$alert-info-bg: $state-info-bg;
$alert-info-text: $state-info-text;
$alert-info-border: $state-info-border;

$alert-warning-bg: $state-warning-bg;
$alert-warning-text: $state-warning-text;
$alert-warning-border: $state-warning-border;

//$alert-danger-bg:             $state-danger-bg;
//$alert-danger-text:           $state-danger-text;
//$alert-danger-border:         $state-danger-border;


//== Progress bars

//##

//** Background color of the whole progress component
$progress-bg: #f5f5f5;
//** Progress bar text color
$progress-bar-color: #fff;
//** Variable for setting rounded corners on progress bar.
$progress-border-radius: $border-radius-base;

//** Default progress bar color
$progress-bar-bg: $brand-primary;
//** Success progress bar color
$progress-bar-success-bg: $brand-success;
//** Warning progress bar color
$progress-bar-warning-bg: $brand-warning;
//** Danger progress bar color
$progress-bar-danger-bg: $brand-danger;
//** Info progress bar color
$progress-bar-info-bg: $brand-info;


//== List group

//##

//** Background color on `.list-group-item`
$list-group-bg: #fff;
//** `.list-group-item` border color
$list-group-border: #ddd;
//** List group border radius
$list-group-border-radius: $border-radius-base;

//** Background color of single list items on hover
$list-group-hover-bg: #f5f5f5;
//** Text color of active list items
$list-group-active-color: $component-active-color;
//** Background color of active list items
$list-group-active-bg: $component-active-bg;
//** Border color of active list elements
$list-group-active-border: $list-group-active-bg;
//** Text color for content within active list items
$list-group-active-text-color: color.adjust($list-group-active-bg, $lightness: 40%);

//** Text color of disabled list items
$list-group-disabled-color: $gray-light;
//** Background color of disabled list items
$list-group-disabled-bg: $gray-lighter;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color;

$list-group-link-color: #555;
$list-group-link-hover-color: $list-group-link-color;
$list-group-link-heading-color: #333;


//== Panels

//##

$panel-bg: #fff;
$panel-body-padding: 15px;
$panel-heading-padding: 10px 15px;
$panel-footer-padding: $panel-heading-padding;
$panel-border-radius: $border-radius-base;

//** Border color for elements within panels
$panel-inner-border: #ddd;
$panel-footer-bg: #f5f5f5;

$panel-default-text: $gray-dark;
$panel-default-border: #ddd;
$panel-default-heading-bg: #f5f5f5;

$panel-primary-text: #fff;
$panel-primary-border: $brand-primary;
$panel-primary-heading-bg: $brand-primary;

$panel-success-text: $state-success-text;
$panel-success-border: $state-success-border;
$panel-success-heading-bg: $state-success-bg;

$panel-info-text: $state-info-text;
$panel-info-border: $state-info-border;
$panel-info-heading-bg: $state-info-bg;

$panel-warning-text: $state-warning-text;
$panel-warning-border: $state-warning-border;
$panel-warning-heading-bg: $state-warning-bg;

$panel-danger-text: $state-danger-text;
$panel-danger-border: $state-danger-border;
$panel-danger-heading-bg: $state-danger-bg;


//== Thumbnails

//##

//** Padding around the thumbnail image
$thumbnail-padding: 4px;
//** Thumbnail background color
$thumbnail-bg: $body-bg;
//** Thumbnail border color
$thumbnail-border: #ddd;
//** Thumbnail border radius
$thumbnail-border-radius: $border-radius-base;

//** Custom text color for thumbnail captions
$thumbnail-caption-color: $text-color;
//** Padding around the thumbnail caption
$thumbnail-caption-padding: 9px;


//== Wells

//##

$well-bg: #f5f5f5;
$well-border: color.adjust($well-bg, $lightness: -7%);


//== Badges

//##

$badge-color: #fff;
//** Linked badge text color on hover
$badge-link-hover-color: #fff;
$badge-bg: $gray-light;

//** Badge text color in active nav link
$badge-active-color: $link-color;
//** Badge background color in active nav link
$badge-active-bg: #fff;

$badge-font-weight: bold;
$badge-line-height: 1;
$badge-border-radius: 10px;


//== Breadcrumbs

//##

$breadcrumb-padding-vertical: 8px;
$breadcrumb-padding-horizontal: 15px;
//** Breadcrumb background color
$breadcrumb-bg: #f5f5f5;
//** Breadcrumb text color
$breadcrumb-color: #ccc;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color: $gray-light;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator: "/";


//== Carousel

//##

$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, .6);

$carousel-control-color: #fff;
$carousel-control-width: 15%;
$carousel-control-opacity: .5;
$carousel-control-font-size: 20px;

$carousel-indicator-active-bg: #fff;
$carousel-indicator-border-color: #fff;

$carousel-caption-color: #fff;


//== Page loader variant 1

//##

$page-loader-v-1-bg: $gray-base;
$page-loader-v-1-padding: 0 25px;
//** spinner
$page-loader-v-1-spinner-width: 56px;
$page-loader-v-1-spinner-height: 56px;

//== Close

//##

$close-font-weight: bold;
$close-color: #000;
$close-text-shadow: 0 1px 0 #fff;


//== Code

//##

$code-color: #c7254e;
$code-bg: #f9f2f4;

$kbd-color: #fff;
$kbd-bg: #333;

$pre-bg: #f5f5f5;
$pre-color: $gray-dark;
$pre-border-color: #ccc;
$pre-scrollable-max-height: 340px;


//== Type

//##

//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px;
//** Text muted color
$text-muted: $gray-light;
//** Abbreviations and acronyms border color
$abbr-border-color: $gray-light;
//** Headings small color
$headings-small-color: $gray-light;
//** Blockquote small color
$blockquote-small-color: $gray-light;
//** Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25);
//** Blockquote border color
$blockquote-border-color: $gray-lighter;
//** Page header border color
$page-header-border-color: $gray-lighter;
//** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal;
//** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint: $grid-float-breakpoint;
//** Horizontal line color.
$hr-border: $oxford-blue;


//== Shadows

//##

$shadow-area-ambient: 0px 0px 1px 0 rgba(0, 0, 0, 0.15);
$shadow-area-xxs: 1px 1px 1px rgba(0, 0, 0, 0.15);
$shadow-area-xs: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
$shadow-area-sm: -1px 2px 5px 0px rgba($mako, .12);
$shadow-area-md: -3px 5px 12px 0px rgba($mako, .16);
$shadow-area-lg: 0 5px 23px 0 rgba(0, 0, 0, 0.15);
$shadow-area-xl: 0 12px 36px 0 rgba(0, 0, 0, .15);

//== Flex Grid system

//## Define your custom flex responsive grid.

$shell-width: 300px;
$shell-xs-width: 480px;
$shell-sm-width: 750px;
$shell-md-width: 970px;
$shell-lg-width: 1200px;
$shell-xl-width: 1800px;

$range-ws: 50px;
$range-ws-sm: 30px;
$range-ws-lg: 66px;

$cell-count: 12;
$cell-spacing: 30px;
$cell-spacing-narrow: 10px;


//== RD Navbar

//##

$rd-navbar-min-font-size: 24px;
$rd-navbar-min-line-height: 48px;
$rd-navbar-min-height: 56px;

$rd-navbar-color: $gray-darker;
$rd-navbar-background: $white;
$rd-navbar-shadow: none;
$rd-navbar-border: 1px solid $porcelain;
$rd-navbar-width: 1200px;

//RD Navbar panel
$rd-navbar-panel-color: $rd-navbar-color;
$rd-navbar-panel-background: $rd-navbar-background;

//RD Navbar nav
$rd-navbar-nav-min-width: 270px;
$rd-navbar-nav-color: $rd-navbar-color;
$rd-navbar-nav-background: transparent;
$rd-navbar-nav-hover-color: $brand-primary;
$rd-navbar-nav-hover-background: transparent;
$rd-navbar-nav-active-color: $rd-navbar-nav-hover-color;
$rd-navbar-nav-active-background: $rd-navbar-nav-hover-background;

//RD Navbar dropdown
$rd-navbar-dropdown-width: 235px;
$rd-navbar-dropdown-background: $white;
$rd-navbar-dropdown-item-color: $text-color;
$rd-navbar-dropdown-item-background: transparent;
$rd-navbar-dropdown-item-hover-color: $brand-primary;
$rd-navbar-dropdown-item-hover-background: transparent;
$rd-navbar-dropdown-item-active-color: $rd-navbar-dropdown-item-hover-color;
$rd-navbar-dropdown-item-active-background: $rd-navbar-dropdown-item-hover-background;

//RD Navbar megamenu
$rd-navbar-megamenu-gutter: 30px;
$rd-navbar-megamenu-background: $white;
$rd-navbar-megamenu-item-color: $text-color;
$rd-navbar-megamenu-item-background: $rd-navbar-dropdown-item-color;
$rd-navbar-megamenu-item-hover-color: $rd-navbar-dropdown-item-hover-color;
$rd-navbar-megamenu-item-hover-background: $rd-navbar-dropdown-item-hover-background;

//RD Navbar togles
$rd-navbar-toggle-preset: "button-lines-arrow-2";
$rd-navbar-search-toggle-preset: "search-preset-1";
$rd-navbar-collapse-toggle-preset: "collapse-preset-1";

//RD Navbar Fixed
$rd-navbar-fixed-height: $rd-navbar-min-height;
$rd-navbar-fixed-line-height: $rd-navbar-min-line-height;
$rd-navbar-fixed-font-size: $rd-navbar-min-font-size;
$rd-navbar-fixed-panel-color: $text-color;
$rd-navbar-fixed-toggle-color: $gray-base;
$rd-navbar-fixed-panel-background: $white;
$rd-navbar-fixed-shadow: none;
$rd-navbar-fixed-border: $rd-navbar-border;

$rd-navbar-fixed-panel-item-color: $abbey;
$rd-navbar-fixed-panel-item-hover-color: $white;
$rd-navbar-fixed-panel-item-background: $rd-navbar-fixed-panel-background;
$rd-navbar-fixed-panel-item-hover-background: $teak;

$rd-navbar-fixed-submenu-toggle-color: $gray-base;
$rd-navbar-fixed-submenu-toggle-hover-color: $brand-primary;
$rd-navbar-fixed-submenu-toggle-focus-color: $white;
//RD Navbar static indents
$navbar-static-nav-indent: 10px;

//RD Navbar fullwidth indents
$navbar-fullwidth-nav-indent: 20px;

$rd-navbar-input-color: $dusty-gray;
$rd-navbar-input-border: 1px solid $porcelain;
$rd-navbar-input-border-radius: 3px;
