// Stucktable
// --------------------------------------------------

.stacktable {
  width: 100%;
  text-align: left;
}

.st-head-row {
  padding-top: 1em;
}

.st-head-row.st-head-row-main {
  padding-top: 0;
  font-size: 1.5em;
}

.st-key {
  width: 49%;
  padding-right: 1%;
  text-align: right;
}

.st-val {
  width: 49%;
  padding-left: 1%;
}

.stacktable.large-only {
  display: none;
}

.stacktable.small-only {
  display: table;
}

@media (min-width: $screen-sm-min) {
  .stacktable.large-only {
    display: table;
  }

  .stacktable.small-only {
    display: none;
  }
}
