// Figure
// --------------------------------------------------
figure {
  img {
    width: 100%;
    max-width: none;
    height: auto;
  }
}

.figure {
  .caption {
    padding: 15px;
  }
}
